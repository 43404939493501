html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-weight: 300;
}

.help-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f86200;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #f86200;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  padding: 10px 20px;
}

.help-button:hover {
  background-color: #f86200;
}

/* Bounce forms on error */
.bounceAnimation {
  animation: bounce 500ms;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Agent Messaging */
.messageInAnimation {
  opacity: 0;
  animation: messageIn 500ms forwards;
}

.message-loading {
  width: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: message-loading 1.1s infinite linear alternate;
}
@keyframes message-loading {
  0% {
    box-shadow: 10px 0 #00468b, -10px 0 #00468b02;
    background: #00468b;
  }
  33% {
    box-shadow: 10px 0 #00468b, -10px 0 #00468b02;
    background: #00468b02;
  }
  66% {
    box-shadow: 10px 0 #00468b02, -10px 0 #00468b;
    background: #00468b02;
  }
  100% {
    box-shadow: 10px 0 #00468b02, -10px 0 #00468b;
    background: #00468b;
  }
}

.hidden {
  opacity: 0;
}

.hidden-none {
  display: none;
}

/* 
.messageInAnimation div.message span.message {
  display: none;
}

.messageInAnimation div.message:before {
  content: "..........";
} */

@keyframes messageIn {
  0% {
    display: none;
    opacity: 0;
    transform: scale(0.1);
  }
  1% {
    display: block;
    opacity: 0;
  }
  60% {
    display: block;
    opacity: 0.8;
    transform: scale(1.05);
  }
  100% {
    display: block;
    opacity: 1;
    transform: scale(1);
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  width: 2px;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c6c6c6;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
