.number-input input {
  text-align: center !important;
  font-size: 1.5rem;
}

.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input input[type="number"] {
  -moz-appearance: textfield;
}
